var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{ref:"header",class:[
    'header',
    {
      'header--overlay': _vm.$route.path === '/' && !_vm.drawerOpen,
      'header--sticky': _vm.isSticky,
    } ]},[_c('div',{staticClass:"header__container"},[_c('BrandLogo'),_vm._v(" "),_c('div',{staticClass:"header__links-container"},[_c('MainNavigation',{staticClass:"header__links",attrs:{"menu":_vm.menu}}),_vm._v(" "),_c('BaseButton',{staticClass:"button--small",attrs:{"path":"/get-in-touch"}},[_vm._v("\n        Get in touch\n      ")]),_vm._v(" "),_c('MenuButton',{class:{
          'header__menu-button--open': _vm.drawerOpen,
        },attrs:{"icon-name":"burger","drawer-open":_vm.drawerOpen,"data-cy":"open-menu-button"},nativeOn:{"click":function($event){return _vm.openDrawer.apply(null, arguments)}}})],1)],1),_vm._v(" "),_c('NavigationDrawer',{attrs:{"menu":_vm.menu}}),_vm._v(" "),_c('Overlay',{attrs:{"visible":_vm.drawerOpen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }