//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NavigationDrawer',
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isAccordionOverflowing: false,
    };
  },
  methods: {
    handleAccordionOpen() {
      // nextTick, because the DOM hasn't been updated in response to
      // the accordion opening
      this.$nextTick(function () {
        this.isAccordionOverflowing = this.checkIsAccordionOverflowing(
          this.$refs.accordionContainer,
          this.$refs.navAccordion.$el
        );
      });
    },
    handleAccordionClose() {
      this.isAccordionOverflowing = false;
    },
    checkIsAccordionOverflowing(container, element) {
      if (!container || !element) return false;

      return container.offsetHeight < element.scrollHeight;
    },
    toggleDrawer(event) {
      if (!event.handledByTheDrawer) {
        event.stopPropagation();
        this.$store.commit('navigation/toggleDrawer');
      }
    },
  },
};
