import { render, staticRenderFns } from "./HeaderDefault.vue?vue&type=template&id=c2e735a6&scoped=true&"
import script from "./HeaderDefault.vue?vue&type=script&lang=js&"
export * from "./HeaderDefault.vue?vue&type=script&lang=js&"
import style0 from "./HeaderDefault.vue?vue&type=style&index=0&id=c2e735a6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2e735a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BrandLogo: require('/usr/src/web-www/components/atoms/brand-logo/BrandLogo.vue').default,MainNavigation: require('/usr/src/web-www/components/molecules/main-navigation/MainNavigation.vue').default,BaseButton: require('/usr/src/web-www/components/atoms/base-button/BaseButton.vue').default,MenuButton: require('/usr/src/web-www/components/organisms/header-default/MenuButton.vue').default,NavigationDrawer: require('/usr/src/web-www/components/molecules/drawer/NavigationDrawer.vue').default,Overlay: require('/usr/src/web-www/components/atoms/overlay/Overlay.vue').default})
