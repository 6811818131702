//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import throttle from 'lodash.throttle';
import BaseButton from '@/components/atoms/base-button/BaseButton.vue';
import BrandLogo from '@/components/atoms/brand-logo/BrandLogo.vue';
import { mapState } from 'vuex';
import menuQuery from '@/apollo/queries/navigation/menu.gql';

export default {
  name: 'Header',
  components: {
    BaseButton,
    BrandLogo,
  },
  data() {
    return {
      isSticky: false,
      scrollPosition: 0,
      menu: [],
    };
  },
  async fetch() {
    /**
     * in an effort to reduce how often Strapi API gets called
     * with the menu data query - for dynamic pages the payload is
     * set with the response from the Strapi REST API.
     * The call happens in the `generate` function in nuxt.config.js
     */
    if (this.$ssrContext?.payload?.navigationItems) {
      this.menu = this.$ssrContext?.payload?.navigationItems;
    } else {
      const { data } = await this.$apolloProvider.defaultClient.query({
        query: menuQuery,
      });

      this.menu = data.navigationItems;
    }
  },
  computed: {
    ...mapState({
      drawerOpen: (state) => state.navigation.drawerOpen,
    }),
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll());

    if (this.$refs.header) {
      this.$store.commit('global/setHeaderHeight', {
        height: this.$refs.header.clientHeight,
      });

      window.addEventListener('resize', this.handleResize());
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll());
    window.removeEventListener('resize', this.handleResize());
  },
  methods: {
    openDrawer(event) {
      if (!this.drawerOpen && !event.handledByTheDrawer) {
        event.stopPropagation();
        this.$store.commit('navigation/toggleDrawer');
      }
    },
    handleScroll() {
      return throttle(() => {
        this.scrollPosition = window.scrollY;
        this.isSticky = this.scrollPosition >= 10;
      }, 50);
    },
    handleResize() {
      return throttle(() => {
        this.$store.commit('global/setHeaderHeight', {
          height: this.$refs.header.clientHeight,
        });
      }, 50);
    },
  },
};
