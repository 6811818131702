var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.cType,_vm._g({tag:"component",class:[
    'typography-micro-header',
    _vm.baseClass,
    ( _obj = {
      'button--disabled': _vm.$props.disabled,
      'button--secondary': _vm.$props.secondary,
      'button--hovered': _vm.hovered
    }, _obj[(_vm.baseClass + "--direction-" + (_vm.$props.iconDirection))] = _vm.$props.icon, _obj ) ],style:(_vm.styles),attrs:{"path":_vm.$props.path || null,"external":_vm.$props.external,"no-follow":_vm.$props.noFollow,"disabled":_vm.$props.disabled,"preserve-query":_vm.$props.preserveQuery,"target":_vm.$props.target},on:{"mousemove":_vm.hover,"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},_vm.$listeners),[(_vm.$props.icon)?_c('BaseIcon',{class:[("icon icon--" + (_vm.$props.iconDirection))],attrs:{"height":"16","width":"16","name":_vm.$props.icon}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }